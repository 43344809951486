html,body {
    font-family: sans-serif;
    padding: 0;
    margin: 0;
    background:#333645;
    color:white;
    min-height: 100%;
    height: 100%;
    width:100%;
}

#root{
    width:100%;
    min-height: 100%;
    height: 100%;
    display: block;
}

.innerContainer{
    display:flex;
    align-items: flex-start;
    justify-content: space-around;
    min-height: 100%;
    height: 100%;
}

h3{
    color: #f0f0f0;
}

h3.dark {
    color: #333645;
}

h3, span {
    text-align: center;
    width: 100%;
}

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    width:100%;
    max-width:1128px;    
    margin: 0 auto;
}

.element {
    height: auto;
    max-width:260px;
    width:100%;
    content: "";
    display: inline-block;
    -webkit-box-shadow: 0px 9px 12px -9px rgba(0, 0, 0, 0.31);
    -moz-box-shadow: 0px 9px 12px -9px rgba(0, 0, 0, 0.31);
    box-shadow: 0px 9px 12px -9px rgba(0, 0, 0, 0.31);
    border:1px solid #f0f0f0;
    border:1px solid #f0f0f0;
    margin: 10px;
    color:black;
    background: white;
}

.element span{
    display:inline-block;
    line-height:40px;
    color: rgba(0,0,0,0.5);
    border-bottom: 1px solid #f0f0f0;
}

svg {
    margin-top:10px;
    cursor: pointer;
    display: inline-block;
    padding: 5px;
}

#canvas {
    display: none;
}


.add-new-icon{
    background:#333645;    
    display:block;
    margin:0 auto;
    width:500px;
}

.form-container{
    margin-bottom: 20px;
    padding:0 10px;
}

.form-container label{
    line-height: 20px;
    color: #f0f0f0;
    display: inline-block;
    
}
.form-container label.long{width: 200px;}
.form-container label.short{width: 110px;}

.form-container input,
.form-container select{
    padding:8px;
    border-radius: 5px;
    border:1px solid black;
    display: inline-block;
}

.form-container input.long{width: calc(100% - 218px)}
.form-container select.long{width: calc(100% - 200px)}

.form-container input.short,
.form-container select.short
{width: calc(100% - 128px)}


.form-container button{
    padding:8px;
    border-radius: 5px;
    border:1px solid #83E8BA;
    width:calc(100%);
    background: #83E8BA;
    color: black;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
}

.form-container input:focus,
.form-container input:active,
.form-container select:focus,
.form-container select:active
    {
    border:1px solid black; 
    outline: none;
}

.form-container input::-webkit-input-placeholder{
    font-style: italic;
    color: black;
    font-size: 12px;
    opacity: 0.6;
}

.mainContainer{
    background:#f6f6f6;    
    display:block;
    margin:0 auto;
    width:100%;
    min-height: 100%;
    height: 100%;
    overflow: auto;
}

div.description{
    color: #afafaf;
    font-size:14px;
    text-align: center;    
}